
/*  label css  */
/*------------*/
label{
  color: $headings-color;
  font-size: $font-size-sm;
  text-transform: uppercase;
  font-weight: $headings-font-weight;
}

/*   Floating label Group css  */
/*----------------------------*/
.form-label-group{
  position: relative;
  margin-bottom: 1.5rem;

  // Form-label
  > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    transition: all .25s ease-in-out;
    padding: .6rem;
    color: $headings-color;
    font-size: .85rem;
    opacity: 0;
  }
  // form-input
  > input, textarea{
    &:focus, &:not(:active){
      &:not(:placeholder-shown) ~ label {
        /* from-input on focus change label color */
        color: rgba($primary, 1) !important;
        transition: all .25s ease-in-out;
        opacity: 1;
      }
    }
    &:not(:focus){
      &:not(:placeholder-shown) ~ label {
        color: $headings-color !important;
      }
    }
    // form-label after entering text in input box
    &:not(:placeholder-shown) ~ label {
      padding : .25rem 0;
      font-size: .7rem;
      top: -20px;
      left: 3px;
    }
  }
}
/*   Horizontal Form Label  */
.col-form-label{
  font-size: $font-size-sm;
}
/*   valid invalid feedback form css   */
.valid-feedback, .invalid-feedback{
  i{
    font-size: 0.6rem;
    position: relative;
    font-weight: bold;
    margin-top: 0.5rem;
  }
}
/*  form control css */
.form-control{
  &:focus{
    &::placeholder{
      /* placeholder transition on focus-in */
      transform: translate(5px);
      transition: all .2s ease;
    }
    ~.form-control-position{
      i{
        color: $primary;
      }
    }
  }
  &:not(:focus){
    &::placeholder{
      /* placeholder transition on focus-out */
      transition: all .2s ease;
    }
  }
  &:-ms-input-placeholder {
    /* placeholder color for IE */
    color: $gray-200;
  }
  // disabled input - border none
  &:disabled{
    border: 0;
  }
  // Is Valid State - boxshadow on Focus
  &.is-valid{
    &:focus{
      box-shadow: 0 3px 8px 0 rgba($success, 0.2);
    }
  }
  // Is InValid State - boxshadow on Focus
  &.is-invalid{
    &:focus{
      box-shadow: 0 3px 8px 0 rgba($danger, 0.2);
    }
  }
}
/*   Input Validation with Tootltips css   */
/*----------------------------------------*/
.needs-validation{
  &.was-validated {
    .form-control{
      &:valid:focus{
        /* box shadow on focus of validation states */
        box-shadow: 0 3px 8px 0 rgba($success, 0.2);
      }
      &:invalid:focus{
        box-shadow: 0 3px 8px 0 rgba($danger, 0.2);
      }
    }
  }
  // valid tooltip
  .valid-tooltip{
    color: $success;
    margin-top: 0.5rem;
  }
  // invalid tooltip
  .invalid-tooltip{
    color: $danger;
    margin-top: 0.5rem;
  }
}
/*  Custom Checkbox css  */
/*----------------------*/
.custom-checkbox, .custom-radio{
  label{
    /* make icon and box in center and disabled checked border color */
    text-transform: none;
    font-size: $font-size-base;
  }
  // Custom-checkbox when not disabled and active
  .custom-control-input:not(:disabled){
    &:active ~ .custom-control-label{
      &::before {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}
/*   Checkbox css  */
/*----------------*/
.checkbox{
  position: relative;
  display: inline-block;

  // basic checkbox - display none
  input[type='checkbox']{
    display: none;
  }

  label{
    /* label css in check box */
    text-transform: none;
    font-size: $font-size-base;
    margin-left: 1.75rem;
    margin-bottom: 0;
  }
  // checkbox box - css
  label:before {
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
  }
  // checkbox box - css
  label:after {
    content: ' ';
    height: 20px;
    width: 20px;
    border: 1px solid $input-border-color;
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
    transition: 100ms ease-in-out;
  }

  // checkbox - arrow css - after checked
  input:checked ~ label:after {
    border-top-style: none;
    border-right-style: none;
     /* IE9 */
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    height: 0.4rem;
    width: 0.8rem;
    border-color: $primary;
    border-radius: 0;
    border-width: 2px;
    top: 6px;
    left: 4px;
  }
  // checkbox checked before
  input:checked ~ label:before{
    background-color: $white;
    border: 1px solid $border-color;
  }

  // disabled checkbox
  input:disabled{
    ~label::before {
      background-color: $body-bg !important;
      border-color: $border-color !important;
      box-shadow: none !important;
    }
    ~label::after {
      border-color: $checkbox-disabled-bg !important;
    }
  }

  // Checkbox Shadow
  &.checkbox-shadow{
    input{
      &:checked~label::before{
        box-shadow: 0 0 6px 0 rgba(22, 22, 22, 0.2);
      }
    }
  }
  // Checkbox Small Size
  &.checkbox-sm{
    input:checked ~ label:after{
      height: 5px;
      width: 8px;
      top: 7px;
    }
    label {
      // before - Square Box - css
      &::before, &::after {
        width: 15px;
        height: 15px;
        top: 3px;
      }
    }
  }
  &.checkbox-icon{
    input:checked ~ label:after{
      /* Checkbox with Icons */
        border: none;
    }
    input:checked ~ label i{
      transform: scale(1);
      transition: 200ms ease-in-out;
    }
    i{
      position: absolute;
      left: 4px;
      top: 5px;
      font-size: 0.85rem;
      transform: scale(1.5);
      transition: all .15s ease-in;
    }
  }
}
/*  Radio Buttons css  */
/*-----------------*/
.radio{
  input[type="radio"] {
    /* Basic Bootstrap Radio Buttons Hide */
    display: none;

    // Checked Radio
    &:checked{
      ~label::after {
        opacity: 1;
        transform : scale(1);
        transition : all .3s cubic-bezier(.35,.9,.4,.9);
      }
    }
    &:disabled{
      ~label::before {
        /* disabled Radio Buttons */
        background-color: $body-bg !important;
        box-shadow: none !important;
      }
      ~label::after {
        background-color: $checkbox-disabled-bg !important;
      }
    }
  }
  // Radio Buttons Label
  label {
    text-transform: none;
    font-size: $font-size-base;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    // After - Circle css
    &::after {
      position: absolute;
      display: inline-flex;
      content: "";
      height: 6px;
      width: 6px;
      background-color: $primary;
      border-radius: 50%;
      left: 5px;
      opacity: 0;
      transform : scale(3.6);
    }
    // before - Rounded Circle - scss
    &::before {
      content: "";
      border: 1px solid $input-border-color;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-right: 0.5rem;
    }
  }
  // Radio Shadow
  &.radio-shadow{
    input{
      &:checked~label::before{
        box-shadow: 0 0 6px 0 rgba(22, 22, 22, 0.2);
      }
    }
  }
  // Radio Small Size
  &.radio-sm{
    label {
      // before - Rounded Circle - scss
      &::before {
        width: 14px;
        height: 14px;
      }
      &:after{
        height: 6px;
        width: 6px;
        left: 4px;
      }
    }
  }
}
/*    Switches css     */
/*--------------------*/
.custom-switch{
  padding-left: 0;

  // on focus when not checked
  .custom-control-input{
    &:focus ~ .custom-control-label::before {
      /* For Switch Handle Animation */
      box-shadow: none !important;
    }
  }
  //custom control label
  .custom-control-label{
    height: 20px;
    width: 42px;
    padding: 0;

    &::before{
      /* For bg color of switch */
      border: none;
      background-color: $switch-bg-color;
      height: 20px;
      box-shadow: none;
      transition: all .25s ease;
      cursor: pointer;
      user-select: none;
      top: 0;
      left: 1px;
    }
    &:after{
      /* For Switch handle */
      position: absolute;
      top: 1px;
      left: 2px;
      box-shadow: none;
      background-color: $switch-indicator-color;
      transition:all .25s ease;
      cursor: pointer;
      user-select: none;
    }

    .switch-icon-left,.switch-icon-right{
      /* For Switch Icon */
      position: absolute;
      cursor: pointer;
      user-select: none;
      top: 2px;
      i{
        font-size: 1rem;
      }
    }
    .switch-icon-left{
      left: 5px;
      color: $white;
    }
    .switch-icon-right{
      right: 5px;
    }
    &:focus{
      outline: 0;
    }
  }

  // after its checked
  .custom-control-input:checked ~ .custom-control-label::after{
    /* For Switch Handle Animation after its checked */
    transform: translateX(1.4rem);
  }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before{
    /* disabled bg color on active */
    background-color: $primary;
  }

  &.custom-switch-glow{
    .custom-control-input:not(:disabled):checked ~ .custom-control-label::before{
      /* Custom Switch Glow */
      box-shadow: 0 0 8px 0 rgba($primary, 0.8) !important;
    }
  }

  // Custom Switch Shadow
  &.custom-switch-shadow{
    .custom-control-input:not(:disabled):checked ~ .custom-control-label::before{
      box-shadow: 0 0 8px 0 rgba($black, 0.4) !important;
    }
  }
}
/* Textarea with Counter */
/*----------------------*/
.counter-value{
  background-color: $primary;
  color: $white;
  padding: 1px 6px;
  font-size: .6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}
/*   Input Group css   */ 
/*--------------------*/
.input-group{
  .input-group-text{
    // Radio in Center
    .radio{
      label{
        &:before{
          margin-right: 0;
        }
      }
    }
    // Checkbox in Center
    .checkbox{
      label{
        &:after, &:before{
          left: 3px;
        }
      }
      input:checked ~ label:after {
        left: 7px;
      }
      // Checkbox-sm in Center
      &.checkbox-sm{
        label{
          &:after, &:before{
            left: 5px;
          }
        }
        input:checked ~ label:after {
          left: 8px;
        }
      }
    }
  }
}
/*   Select Inputs   */
/*------------------*/
// change arrow icon
select.form-control {
  &:not([multiple="multiple"]){
    background-image:
      url("../../app-assets/images/pages/arrow-down.png");
    background-position:
      calc(100% - 12px) 13px,
      calc(100% - 20px) 13px,
      100% 0;
    background-size:
      12px 12px,
      10px 10px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 1.5rem;
  }
}

/*  customs-select css  */
/*---------------------*/
.custom-select{
  /* remove double arrow */
    -moz-appearance: none; // For firefox
    -webkit-appearance: none; // for chorme
}
select::-ms-expand {
  /* remove bootstrap default arrow in IE11 */
  display: none;
}
/*  Bootstrap touchspin css  */
/*--------------------------*/
.bootstrap-touchspin{
  &.input-group{
    input[type=number] {
      /* Remove arrow for Firefox */
      -moz-appearance: textfield;
    }
    .input-group-btn{
      .btn{
        padding: 0.4rem 1rem;
      }
    }
    // Vertical Touchspin
    input{
      &.touchspin-vertical{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        ~.input-group-btn-vertical{
          .bootstrap-touchspin-up{
            border-bottom: 1px solid;
          }
        }
      }
    }
    .bootstrap-touchspin-down, .bootstrap-touchspin-up{
      i{
        font-size: 0.8rem;
        position: relative;
        top: 0;
        font-weight: bold;
      }
    }
    // Disabled Touchspin
    &.disabled-touchspin{
      input{
        border: 1px solid $touchspin-border-color !important;
      }
      .bootstrap-touchspin-down, .bootstrap-touchspin-up{
        background-color: $gray-100 !important;
        cursor: default;
      }
    }
    // Touchspin Large Padding
    &.input-group-lg{
      .input-group-btn{
        .btn{
          padding: 0.5rem 1.2rem;
        }
      }
    }
    // Touchspin Small Padding
    &.input-group-sm{
      .input-group-btn{
        .btn{
          padding: 0.5rem 0.8rem;
        }
      }
    }
  }
  // Touchspin - Change bg-color on min-max value
  .btn.disabled-max-min, .btn.disabled-max-min:hover{
    background-color: $gray-100 !important;
    cursor: default;
  }
}

/*   Number Type Input Box css  */
/*-----------------------------*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
  // Remove arrow on hover 
    -webkit-appearance: none;
}

/*  IE Specific CSS  */
/*------------------*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* radio buttons - checked circle icon */
  .radio {
    input[type='radio']{
      &:checked ~ label::after{
        margin-top: 0.4rem;
      }
    }
  }
}
