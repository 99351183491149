// ===============================================================================================
//   File Name: spinners.scss
//   ----------------------------------------------------------------------------------------------
//   Item Name: Frest HTML Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
//================================================================================================
/*   spinner css   */
/*----------------*/ 
.spinner-border-lg {
  width: $spinner-width-lg;
  height: $spinner-height-lg;
}
.spinner-grow-lg {
  width: $spinner-width-lg;
  height: $spinner-height-lg;
}
