/*	 media css 	*/
/*-------------*/ 
.media-list{
  // media
	.media{
		padding: 1.25rem;
		width: 100%;
		margin-top: 0;
	}
	a.media{
		color: $gray-600 !important;
	}
}
/* media border */
.media-bordered{
	.media{
		border-top: 1px solid $border-color;
		&:first-child{
			border-top: 0;
		}
	}
}
