// ================================================================================================
//  File Name: bootstrap-extended.scss
//  Description: List of modified Bootstrap files. This is an actual copy of bootstrap.scss
//                excluding files that have not been modified.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Frest HTML Admin Template
//  Version: 1.0
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

/*------------------------------------------------------------------
[Table of contents]

- Reboot css
- Font size [Small / Medium / Large]
- Font weight
- Grid
- Table
- Form Control
-   Floating label Group
-   Checkbox
-   Radio
-   Switch
-   Textarea counter
-   Input Group
-   Select & Custom Select
-   Bootstrap Touchspin
-   Number Input
- Button
- Collapse
- Dropdown
- Carousel
- Navbar
- Card
- Breadcrumb
- Badges
- navs, tabs, pills
- Alerts
- Media objects
- Tooltip
- Progress Bar
- List Group
- Toasts
- Collapse and Accordion
- Pagination
- Spinner
- Modal
- Popover
- Utilities css
-------------------------------------------------------------------*/

// Core variables and mixins
@import "bootstrap/functions";
@import "bootstrap/mixins";
@import "bootstrap-extended/mixins";

// Load variable overrides
@import "core/variables/variables";
@import "bootstrap/variables";

// Custom template mixins
@import "core/mixins/alert";

// Overrides user variable
@import "core/variables/components-variables";

// // Core CSS
@import "bootstrap-extended/reboot";
@import "bootstrap-extended/type";
@import "bootstrap-extended/code";
@import "bootstrap-extended/grid";
@import "bootstrap-extended/tables";
@import "bootstrap-extended/forms";
@import "bootstrap-extended/buttons";

// // Components
@import "bootstrap-extended/animation";
@import "bootstrap-extended/dropdown";
@import "bootstrap-extended/carousel";
@import "bootstrap-extended/navbar";
@import "bootstrap-extended/card";
@import "bootstrap-extended/breadcrumb";
@import "bootstrap-extended/badge";
@import "bootstrap-extended/nav";
@import "bootstrap-extended/alert";
@import "bootstrap-extended/media";
@import "bootstrap-extended/tooltip";
@import "bootstrap-extended/progress";
@import "bootstrap-extended/list-group";
@import "bootstrap-extended/toast";
@import 'bootstrap-extended/collapse';
@import 'bootstrap-extended/pagination';
@import 'bootstrap-extended/spinners';

// // Components w/ JavaScript
@import "bootstrap-extended/modal";
@import "bootstrap-extended/popover";

// // Utility classes
@import "bootstrap-extended/utilities";
