/* Grid examples  */
/*---------------*/
.bd-example-row {
  .row + .row {
    margin-top: 1rem;
  }
  .row {
    > .col,
    > [class^="col-"] {
      padding-top: .75rem;
      padding-bottom: .75rem;
      background-color: rgba(86,61,124,.15);
      border: 1px solid rgba(86,61,124,.2);
    }
  }

  .flex-items-top,
  .flex-items-middle,
  .flex-items-bottom {
    min-height: 6rem;
    background-color: rgba(255,0,0,.1);
  }
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255,0,0,.1);
}

.bd-highlight {
  background-color: rgba(86, 61, 124, .15);
  border: 1px solid rgba(86, 61, 124, .15);
}

// Grid mixins 
.example-container {
  width: 800px;
  @include make-container();
}

.example-row {
  @include make-row();
}

.example-content-main {
  @include make-col-ready();

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}

.example-content-secondary {
  @include make-col-ready();

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}
