/*    Nav, Navtabs and Navpills   */
/*-------------------------------*/
.nav {
  // common style for nav
  &.nav-tabs,
  &.nav-pills {
    // nav-item
    .nav-item {
      margin-right: 0.8rem;
      &.dropdown {
        &.show{
          .dropdown-toggle{
            &:hover{
              color: $white;
            }
          }
          .dropdown-menu{
            border-radius: $border-radius;
          }
        }
      }
      // nav-link
      .nav-link {
        border-radius: $nav-pills-border-radius;
        &.disabled {
          opacity: 0.5;
        }
        // nav-link hover style
        &:hover {
          color: $gray-600;
        }
        // style for icon in nav-link
        i {
          margin-right: 0.2rem;
        }
        // nav-link active style
        &.active {
          box-shadow: 0 2px 4px 0 rgba($primary, 0.5);
          // nav-link active hover
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  // specific style for nav-tabs
  &.nav-tabs {
    margin-bottom: 1rem;
    border-bottom-color: $tabs-border-color;

    // specific for nav-tabs's nav-item
    .nav-item {
      padding-bottom: 0.8rem;
      position: relative;

      // nav-tabs active indicator style
      &.current {
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          margin: auto;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 8px solid $tabs-border-color;
          bottom: 0;
          left: 0;
          right: 0;
        }
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          margin: auto;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 6px solid $white;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
    // tab-content
    ~ .tab-content {
      padding-left: 0.6rem;
      color: $gray-600;
    }
  }
  // speficic style for nav-pills
  &.nav-pills {
    .nav-item {
      margin-bottom: 1rem;
    }
    ~ .tab-content {
      /* specific style for tab-content */
      background-color: $white;
      padding: 1.13rem 1.16rem 0.6rem 1.13rem;
      box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
      border-radius: $border-radius;
      color: $gray-600;
    }
  }
}
/* specific style for Vertically Stacked Pills */
.pills-stacked{
  .flex-column {
    .nav-item {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .tab-content{
    background-color: $white;
    padding: 1.13rem 1.16rem 0.6rem 1.13rem;
    box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
    border-radius: $border-radius;
    color: $gray-600;
  }
}
// nav-link background color overlap by bootstrap's core style due to less inheritence
.nav-tabs,
.nav-pills {
  .nav-link {
    background-color: $nav-link-bg-color;
    color: $gray-600;
  }
}
